<template>
  <div>
    <component :is='contentComponentBySessionType'
      :show-session-content-select='false'
      :show-session-today-watched-time='false'
      :show-session-title-top='true'
      :show-session-speakers='false'
      :show-session-details-full='false'
      :show-session-details-short='false'
      :show-session-questions-box='false'
      :show-session-remaining-sessions='false'
      :show-session-troubleshoot-button='false'
      :append-watermark-to-video-player='true'
      page-background-custom-css=''
      video-background-custom-css=''  />
    <div v-html='selectedSessionConfigOtherSessionPageContent'></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LiveSession  from '@/components/samsungfire-live/LiveSession.vue'
import VodSession   from '@/components/sessions/VodSession.vue'

export default {
  name: 'SinglePageSession',
  components: {
    LiveSession,
    VodSession,
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to !== from) {
          this.getSingleSessionV2(this.$route.query.session_id)
          this.getSessionNotices(this.$route.query.session_id)
          this.unsubscribeFromNotices()
          this.subscribeToNotices(this.$route.query.session_id)
          this.unsubscribeFromGoto()
          this.subscribeToGoto(this.$route.query.session_id)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('sessions', [
      'selectedSessionType',
      'selectedSessionConfigOtherSessionPageContent',
    ]),
    ...mapGetters('events', [
      'showingEventId',
    ]),
    contentComponentBySessionType () {
      if (this.selectedSessionType === 'vod') {
        return 'vod-session'
      } else {
        return 'live-session'
      }
    }
  },
  methods: {
    ...mapActions('sessions', [
      'getCurrentSessionId',
      'getSingleSessionV2',
      'subscribeToGoto',
      'unsubscribeFromGoto',
    ]),
    ...mapActions('sessionNotices', [
      'getSessionNotices',
      'subscribeToNotices',
      'unsubscribeFromNotices',
    ]),
    ...mapActions('userActions', [
      'createUserAction'
    ])
  },
  mounted () {
    this.createUserAction({
      userActionName: 'open_single_session_page',
      userActionData: {
        event_id: this.showingEventId,
        session_id: this.$route.query.session_id,
        session_type: this.contentComponentBySessionType
      }
    })
  }
}
</script>
