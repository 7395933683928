<template>
  <div>
    <session-notices />
    <session-redirect-message />
    <content-background-cover
      id='page-background'
      :custom-css='pageBackgroundCustomCss'
      background-color='#FFF'
      class='z-0' />
    <div class='py-2 lg:py-8 relative'>
      <content-background-cover
        id='video-background'
        :custom-css='videoBackgroundCustomCss'
        background-color='#F3F4F6'
        class='z-0'
        :top-offset='0' />
      <h1 v-if='showSessionTitleTop' 
        class='text-lg lg:text-2xl font-semibold mb-4 px-2 lg:px-0'>
        {{ selectedSession.title }}
        <span v-if='selectedSession.subtitle'>- {{ selectedSession.subtitle }}</span>
      </h1>
      <div v-if='showAboveVideoContent' 
        class='text-right flex flex-row items-center pb-2 px-2 lg:px-0'
        :class='sessionContentSelectBoxClass'>
        <session-content-select v-if='showSessionSelect' />
        <session-today-watched-time v-if='showTodayWatchedTime' />
      </div>
      <div ref='sessionContentContainer'
           class='flex flex-col lg:flex-row lg:gap-x-4 lg:items-stretch lg:justify-center relative'>
        <component :is='selectedVideoPlayerComponent'
          v-if='hasSelectedSessionCurrentContent' 
          :key='selectedSessionId'
          :watermark-text='watermarkString'
          watermark-helper-text='전략회의 내용은 대외비에 해당하오니 보안에 유의하시기 바랍니다.'
          class='flex-shrink-0 z-10 relative flex flex-row items-center justify-center'
          :style='`width: ${sessionContentContainerWidth}px; height: ${sessionContentContainerHeight}px;`'
          @enter-live-session='enterLiveSession' />
        <session-question v-if='showQuestionsNextToVideo'
          class='lg:flex-grow mt-2 lg:mt-0 z-10 lg:rounded-lg'
          :style='`height: ${sessionContentContainerHeight}px`' />
      </div>
      <div class='flex flex-row items-center justify-between gap-x-2 my-4'>
        <button
          v-if='showSessionTroubleshootButton'
          class='underline text-gray-600'
          @click='openVideoTroubleShoot'>
          Troubleshoot my video
        </button>
        <button
          v-if='enteredLiveSession & selectedSessionConfigShowSessionExitButton'
          class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
          :class='exitLiveSessionButtonStyle'
          :style="`background-color: ${eventMainThemeColor};`"
          :disabled='exitedLiveSession'
          @click='exitLiveSession'>
          {{ exitLiveSessionButtonText }}
        </button>
        <modal
          name='video-troubleshoot-modal'
          :adaptive='true'
          :height='400'>
          <div class='p-8'>
            <h1 class='capitalize text-2xl font-semibold mb-8'>라이브스트리밍 문제 해결</h1>
            <div class='flex flex-col justify-center items-center h-64'>
              <p class='text-lg'>
                <span class='inline-block underline cursor-pointer hover:bg-gray-100' @click='refreshPage'>Refresh 버튼</span> 을 눌러도 플레이가 되지 않을시 모바일로 시청해주세요.
              </p>
              <div class='row-centered justify-center gap-x-8 my-8'>
                <button 
                  class='py-3 w-40 lg:w-48 rounded-md border border-gray-200 hover:shadow-md opacity-90 hover:opacity-100 text-sm uppercase bg-gray-800 text-white' 
                  @click='refreshPage'>
                  Refresh Page
                </button>
              </div>
            </div>
          </div>
        </modal>
        <modal
          name='video-not-started-modal'
          :adaptive='true'
          :height='200'>
          <div class='p-8'>
            <h1 class='capitalize text-2xl font-semibold mb-8'>라이브스트리밍 문제 해결</h1>
            <div class='flex flex-col justify-center items-center'>
              <p class='text-lg'>
                현재 라이브스트리밍 준비중입니다. 시작 시간을 확인해주세요.
              </p>
            </div>
          </div>
        </modal>
      </div>
    </div>
    <div v-if='!isFullscreenVideo'>
      <session-sponsors-carousel v-if='selectedSessionCarouselSponsors.length'
        class='px-2 lg:px-0 mt-2' />
      <session-other-sessions v-if='hasOtherSessions'
        class='mt-16 px-2 lg:px-0' />
      <session-details-short v-if='showSessionDetailsShort'
        class='mt-16 px-2 lg:px-0' />
      <session-details-full v-if='showSessionDetailsFull'
        class='mt-16 px-2 lg:px-0'
        :session='sessionDetails' />
      <session-speakers v-if='showSessionSpeakers'
        class='mt-16 px-2 lg:px-0' />
      <program-schedule class='mt-16 px-2 lg:px-0' v-if='hasSessions && showSessionRemainingSessions' />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'
import LiveVideoContent        from '@/components/samsungfire-live/LiveVideoContent.vue'
import LiveVideoContentVimeo   from '@/components/contents/v2/LiveVideoContentVimeo.vue'
import ProgramSchedule         from '@/components/programs/ProgramSchedule.vue'
import SessionRedirectMessage  from '@/components/sessions/SessionRedirectMessage.vue'
import SessionNotices          from '@/components/sessions/SessionNotices.vue'
import SessionQuestion         from '@/components/sessions/SessionQuestion.vue'
import SessionOtherSessions    from '@/components/sessions/SessionOtherSessions.vue'
import SessionSpeakers         from '@/components/sessions/SessionSpeakers.vue'
import SessionSponsorsCarousel from '@/components/sessions/SessionSponsorsCarousel.vue'
import SessionDetailsShort     from '@/components/sessions/SessionDetailsShort.vue'
import SessionContentSelect    from '@/components/sessions/SessionContentSelect.vue'
import SessionTodayWatchedTime from '@/components/sessions/SessionTodayWatchedTime.vue'
import SessionDetailsFull      from '@/components/sessions/SessionDetailsFull.vue'

export default {
  name: 'LiveSession',
  props: {
    showSessionContentSelect: {
      default: true,
      type: Boolean
    },
    showSessionTodayWatchedTime: {
      default: true,
      type: Boolean
    },
    showSessionSpeakers: {
      default: false,
      type: Boolean
    },
    showSessionTitleTop: {
      default: false,
      type: Boolean
    },
    showSessionDetailsFull: {
      default: true,
      type: Boolean
    },
    showSessionDetailsShort: {
      default: false,
      type: Boolean
    },
    showSessionQuestionsBox: {
      default: true,
      type: Boolean
    },
    showSessionRemainingSessions: {
      default: true,
      type: Boolean
    },
    showSessionTroubleshootButton: {
      default: true,
      type: Boolean
    },
    appendWatermarkToVideoPlayer: {
      default: false,
      type: Boolean
    },
    // need to fix with event config
    pageBackgroundCustomCss: {
      default: '',
      type: String
    },
    videoBackgroundCustomCss: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      selectableVideoPlayer: ['videojs', 'vimeo'],
      selectedVideoPlayer: 'videojs',
      sessionContentContainerWidth: 0,
      exitedLiveSession: false,
      enteredLiveSession: false,
      watermarkString: '',
    }
  },
  components: {
    ContentBackgroundCover,
    LiveVideoContent,
    LiveVideoContentVimeo,
    ProgramSchedule,
    SessionContentSelect,
    SessionDetailsFull,
    SessionDetailsShort,
    SessionNotices,
    SessionOtherSessions,
    SessionQuestion,
    SessionRedirectMessage,
    SessionSpeakers,
    SessionSponsorsCarousel,
    SessionTodayWatchedTime,
  },
  watch: {
    'selectedSessionId': {
      handler: function (newVal) {
        if (newVal && this.showSessionDetailsFull) {
          this.getSingleSessionDetails(this.selectedSessionId)
        }
      },
      immediate: true
    },
    'appendWatermarkToVideoPlayer': {
      handler: function (newVal) {
        if (newVal) {
          this.getProfile().then(resp => {
            this.watermarkString = `${resp.email} ${resp.name}`
          })
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState([
      'isFullscreenVideo',
    ]),
    ...mapState('sessions', [
      'sessionDetails',
      'selectedSession',
    ]),
    ...mapGetters('sessions', [
      'hasSessions',
      'hasManySelectedSessionListOfContents',
      'selectedSessionCurrentContent',
      'hasSelectedSessionCurrentContent',
      'selectedSessionOtherSessions',
      'selectedSessionId',
      'selectedSessionConfigForCredit',
      'selectedSessionCarouselSponsors',
      'selectedSessionConfigShowSessionExitButton',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'eventMainThemeColor'
    ]),
    hasOtherSessions () {
      return (this.selectedSessionOtherSessions && this.selectedSessionOtherSessions.length > 0)
    },
    sessionContentContainerHeight () {
      return (this.sessionContentContainerWidth &&  this.selectedSessionCurrentContent.height && this.selectedSessionCurrentContent.width)
             ? Math.max(parseInt((this.sessionContentContainerWidth) * (this.selectedSessionCurrentContent.height / this.selectedSessionCurrentContent.width)), 360)
             : this.sessionContentContainerWidth * (9/16) // assuming this is the standard vimeo aspect ratio
    },
    sessionContentSelectBoxClass () {
      return this.showSessionContentSelect && this.hasManySelectedSessionListOfContents ? 'justify-between' : 'justify-end'
    },
    selectedVideoPlayerComponent () {
      if (this.selectedVideoPlayerVideoJs) {
        return 'live-video-content'
      } else if (this.selectedVideoPlayerVimeo) {
        return 'live-video-content-vimeo'
      } else {
        return ''
      }
    },
    showAboveVideoContent () {
      return (this.showSessionContentSelect && this.hasManySelectedSessionListOfContents) ||
             (this.showSessionTodayWatchedTime && this.selectedSessionConfigForCredit)
    },
    showSessionSelect () {
      return this.showSessionContentSelect && this.hasManySelectedSessionListOfContents
    },
    showTodayWatchedTime () {
      return this.showSessionTodayWatchedTime && this.selectedSessionConfigForCredit
    },
    showQuestionsNextToVideo () {
      return !this.isFullscreenVideo && this.showSessionQuestionsBox
    },
    selectedVideoPlayerVideoJs () {
      return this.selectedVideoPlayer === 'videojs'
    },
    selectedVideoPlayerVimeo () {
      return this.selectedVideoPlayer === 'vimeo'
    },
    exitLiveSessionButtonText () {
      return this.exitedLiveSession ? '평점퇴실이 완료되었습니다' : '평점퇴실'
    },
    exitLiveSessionButtonStyle () {
      return this.exitedLiveSession ? 'opacity-50 cursor-default' : ''
    }
  },
  methods: {
    ...mapActions('sessions', [
      'getSessions',
      'getSingleSessionDetails',
    ]),
    ...mapActions('userActions', [
      'createUserAction'
    ]),
    ...mapActions('users', [
      'getProfile',
    ]),
    getContainerWidth () {
      if (this.$refs.sessionContentContainer.clientWidth >= 1024) {
        this.sessionContentContainerWidth = (this.showQuestionsNextToVideo) ? this.$refs.sessionContentContainer.clientWidth * 0.7 : this.$refs.sessionContentContainer.clientWidth
      } else {
        this.sessionContentContainerWidth = this.$refs.sessionContentContainer.clientWidth
      }
    },
    openVideoTroubleShoot () {
      this.$modal.show('video-troubleshoot-modal')
    },
    refreshPage () {
      this.$router.go(0)
    },
    switchToBackupSource () {
      this.toggleVideoSource()
      this.$modal.hide('video-troubleshoot-modal')
    },
    toggleVideoSource () {
      if (this.selectedVideoPlayerVideoJs) {
        this.selectedVideoPlayer = 'vimeo'
      } else {
        this.selectedVideoPlayer = 'videojs'
      }
    },
    exitLiveSession () {
      this.createUserAction({
        userActionName: 'click_live_session_exit_button',
        userActionData: {
          event_id: this.showingEventId,
          session_id: this.selectedSession.id,
          content_id: this.selectedSessionCurrentContent.id,
          video_player: this.selectedVideoPlayer
        }
      }).then(() => {
        this.exitedLiveSession = true
      })
    },
    enterLiveSession () {
      this.enteredLiveSession = true
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getContainerWidth)
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getContainerWidth)
      this.getContainerWidth()
    })
    this.getSessions('remaining')
  }
}
</script>
